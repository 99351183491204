import React, { useState } from 'react'

export const TikalForm = () => {
    const [price, setPrice] = useState(0);
    const [display, setDisplay] = useState(['none', 'Ver precio personalizado']);

    const calculateNewRate = ( marked, rate ) => {
        if (marked) {
            return price + rate;
        } else {
            return price - rate;
        }
    };

    const handleCheck = e => {
        const checkbox = e.target.id;
        const checked = e.target.checked;


        switch (checkbox) {
            case 'transporteInOut':
                // TODO: set an if for rate depending on the departure point
                setPrice( calculateNewRate(checked, 25) );
                break;
            case 'airTicket':
                setPrice( calculateNewRate(checked, 200) );
                break;
            case 'greet':
                setPrice( calculateNewRate(checked, 15) );
                break;
            case 'transporteTikal':
                setPrice( calculateNewRate(checked, 20) );
                break;
            case 'admissionFee':
                setPrice( calculateNewRate(checked, 20) );
                break;
            case 'tourGuide':
                setPrice( calculateNewRate(checked, 30) );
                break;
            case 'lunch':
                setPrice( calculateNewRate(checked, 15) );
                break;
            default:
                break;
        }
    };

    const showHide = e => {
        e.preventDefault();
        if (display[0] === 'none') {
            setDisplay(['block', 'Ocultar precio personalizado']);
        } else {
            setDisplay(['none', 'Ver precio personalizado']);
        }
    }
  return (
    <>
        {/* <span><a id="showHideTikalForm" onClick={ showHide } href='./'>{ display[1] }</a></span> */}
        <div className="fluid-container" style={{ display: display[0], }}>
            <hr />
            <h4>Servicios</h4>
            <p>Selecciona los servicios que quiera agregar en su tour:</p>
            <form>
                <div className="form-group form-check">
                    <input className='form-check-input' type="checkbox" onChange={handleCheck} id="transporteInOut" />
                    <label className='form-check-label' htmlFor="transporteInOut">Transporte al aeropuerto</label>
                </div>
                <div className="form-group form-check">
                    <input className='form-check-input' type="checkbox" onChange={handleCheck} id="airTicket" />
                    <label className='form-check-label' htmlFor="airTicket">Boleto Aereo</label>
                </div>
                <div className="form-group form-check">
                    <input className='form-check-input' type="checkbox" onChange={handleCheck} id="greet" />
                    <label className='form-check-label' htmlFor="greet">Recibimiento en el Aeropuerto de Flores</label>
                </div>
                <div className="form-group form-check">
                    <input className='form-check-input' type="checkbox" onChange={handleCheck} id="transporteTikal" />
                    <label className='form-check-label' htmlFor="transporteTikal">Transporte terrestre hacia Tikal (ida y vuelta)</label>
                </div>
                <div className="form-group form-check">
                    <input className='form-check-input' type="checkbox" onChange={handleCheck} id="admissionFee" />
                    <label className='form-check-label' htmlFor="admissionFee">Entrada al parque Tikal</label>
                </div>
                <div className="form-group form-check">
                    <input className='form-check-input' type="checkbox" onChange={handleCheck} id="tourGuide" />
                    <label className='form-check-label' htmlFor="tourGuide">Guía turístico</label>
                </div>
                <div className="form-group form-check">
                    <input className='form-check-input' type="checkbox" onChange={handleCheck} id="lunch" />
                    <label className='form-check-label' htmlFor="lunch">Almuerzo</label>
                </div>
                <span><p>Su total personalizado es: <b className='tikal-form-customize-price'>USD { price.toFixed(2) }</b></p></span>
            </form>
        </div>
    </>
  )
}
