import React from 'react'
import { useCustomForm } from '../../hooks/useCustomForm';

export const ModalAddRegisterComponent = () => {
  const [ values, handleInputChange ] = useCustomForm({
    fecha: new Date(),
    cliente: '',
    pax: 0,
    servicio: '',
    hotel: '',
    contacto: '',
    status: 'unset',
    rack: 0,
    comic: 0,
    saldo: 0,
    neta: 0,
    free: 0,
  });
  const { fecha, cliente, pax, servicio, hotel, contacto, rack, comic, neta, } = values;
  return (
    <div id='modalRegisters' className='modal fade' aria-hidden='true'>
        <div className='modal-dialog'>
            <div className='modal-content'>
                <div className='modal-header'>
                    <label className='h5'>Nuevo Registro</label>
                    <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                {/* Cuerpo del Modal */}
                <div className='modal-body'>
                    <input type='hidden' id='id' value={1}></input>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-user"></i></span>
                        <input
                            type='date'
                            id='fecha'
                            className='form-control'
                            // placeholder='Ingrese nombre'
                            value={ `${fecha.getDay()}/${fecha.getMonth()}/${fecha.getFullYear()}` }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-phone"></i></span>
                        <input
                            type='text'
                            id='cliente'
                            className='form-control'
                            placeholder='Cliente'
                            value={ cliente }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-envelope"></i></span>
                        <input
                            type='number'
                            id='pax'
                            className='form-control'
                            placeholder='Pax'
                            value={ pax }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-envelope"></i></span>
                        <input
                            type='text'
                            id='servicio'
                            className='form-control'
                            placeholder='Servicio'
                            value={ servicio }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-envelope"></i></span>
                        <input
                            type='text'
                            id='hotel'
                            className='form-control'
                            placeholder='Hotel'
                            value={ hotel }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-envelope"></i></span>
                        <input
                            type='text'
                            id='contacto'
                            className='form-control'
                            placeholder='Contacto'
                            value={ contacto }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-envelope"></i></span>
                        <select id="status" name='status' onChange={ handleInputChange } defaultValue={0} placeholder='Status' >
                            <option value="Reservada">Reservada</option>
                            <option value="Cobrada">Cobrada</option>
                            <option value="En Curso">En Curso</option>
                            <option value="Completada">Completada</option>
                      </select>
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-envelope"></i></span>
                        <input
                            type='text'
                            id='rack'
                            className='form-control'
                            placeholder='Rack'
                            value={ rack }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-envelope"></i></span>
                        <input
                            type='text'
                            id='comic'
                            className='form-control'
                            placeholder='Comic'
                            value={ comic }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <div className='input-group mb-3'>
                        <span className='input-group-text'><i className="fa-solid fa-envelope"></i></span>
                        <input
                            type='text'
                            id='neta'
                            className='form-control'
                            placeholder='Neta'
                            value={ neta }
                            onChange={ handleInputChange }
                        />
                    </div>
                    {/* Boton Guardar */}
                    <div className='d-grid col-6 mx-auto'>
                        <button className='btn btn-success btn-block'>
                            <i className='fa-solid fa-save'></i> Guardar
                        </button>
                    </div>
                </div>
                {/* Pie del Modal */}
                <div className='modal-footer'>
                    <button id='btnCerrar' type='button' className='btn btn-secondary' data-bs-dismiss='modal'>
                        <i className='fa-solid fa-window-close'></i> Cerrar
                    </button>
                </div>

            </div>
        </div>
    </div>
  )
}
