import { useState } from "react";
import { useEffect } from "react"


export const ReservarComponent = ({ handleClick, text }) => {
  const [reserveClass, setReserveClass] = useState(false);

  useEffect(() => {
    setReserveClass( !reserveClass );
  }, [text]);
  
  return (
    <button className={`btn btn-${ reserveClass ? 'success' : 'outline-success' } reservation-button`} onClick={ handleClick } id="btn-reservation-button">
        { text }
    </button>
  )
}
