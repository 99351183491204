import { getTour } from "../helpers/getTour";
import { ContactComponent } from "./ContactComponent";
import { FooterComponent } from "./FooterComponent";
import { TourOverview } from "./TourOverview";

export const AntiguaToursPage = () => {

  const tours = ['antigua-antigua', 'antigua-lago', 'antigua-pacaya', 'antigua-acatenango', 'antigua-chichi', 'antigua-tikal', 'antigua-city', 'antigua-hobbitenango', 'antigua-mistico',];
  
  return (
    <div>
      <div>
      </div>
      <div className="tours-departing">
        <h1 className="standard-version">Tours saliendo desde Antigua Guatemala</h1>
        <h2 className="phone-version">Tours saliendo desde Antigua Guatemala</h2>
      </div>
      <div className="tours-overviews">
        {
          tours.map( (tourName, i) => {
            const tour = getTour(tourName);
            const { photos:img, title, id, time, priceImg, stars:calification, amountReviews:coments } = tour;
            return (
              <div className="tour" key={`tour-${i}`}>
                <TourOverview cardImg={img[0]} cardImgAlt={title} cardTitle={title} cardLink={`../tours/${id}`} cardPrice={priceImg} cardTime={time} cardCalification={calification} cardAmountComments={coments} />
              </div>
            );
          })
        }
      </div>
      <ContactComponent />
      <FooterComponent />
    </div>
  )
}
