import React from 'react'
import { FooterComponent } from '../components'

export const PrivateTransportationPage = () => {
  return (
    <>
        <div className='circuits'>
            <h3>Próximamente...</h3>
            <div className="spinner-border" role='status'>
                <span className="sr-only">Loading...</span>
            </div>
        </div>
        <FooterComponent />
    </>
  )
}
