import React from 'react'
import { FooterComponent } from '../components/FooterComponent'

export const AboutPage = () => {
  return (
    <>
        <div className='container-about'>
            <div className='about-section'>
                <h1>Nuestra Visión</h1>
                <hr />
                {/* <p>Our vision is to become a leading provider of exceptional travel experiences, recognized for our commitment to quality, sustainability, and customer satisfaction. We strive to create unforgettable journeys that inspire our clients to explore the world, connect with diverse cultures, and foster a deep appreciation for the beauty and uniqueness of each destination. Through innovative and personalized services, we aim to exceed expectations, promote responsible travel practices, and contribute positively to the communities and environments we operate in. Our vision is to be the preferred choice for travelers seeking enriching, authentic, and transformative experiences, leaving a lasting impact on their lives and the places they visit.</p> */}
                <p>Nuestra visión es convertirnos en un excepcional proveedor de experiencias de viaje. Destacados por nuestro compromiso con la calidad, sustentabilidad y satisfacción del cliente. Nos esforzamos en proveer experiencias de viaje inolvidables que inspiren a nuestros clientes a conocer Guatemala, conectando con nuestra cultura mientras promovemos el valor de la belleza de cada uno de nuestros destinos únicos. A través de la innovación y servicios personalizados, buscamos exceder las expectativas de nuestros clientes mientras promovemos prácticas de viaje responsables y contribuir a las comunidades y entornos en donde se realizan los <i>tours</i>. Queremos ser la opción preferida de turistas que buscan experiencias de viaje únicas y auténticas.</p>
            </div>
            <div className='about-section'>
                <h1>Nuestra Misión</h1>
                <hr />
                {/* <p>Our mission is to provide exceptional travel experiences that create lifelong memories for our clients. We are dedicated to delivering personalized and high-quality services, ensuring customer satisfaction at every step of their journey. Through our extensive network of partners and destinations, we aim to offer diverse and immersive travel opportunities that cater to different interests and preferences. We are committed to promoting sustainable tourism practices, respecting local cultures, preserving natural resources, and supporting the communities we operate in. Our mission is to be a trusted and reliable travel partner, inspiring and empowering individuals to explore the world responsibly while fostering a deeper understanding and appreciation for global diversity.</p> */}
                <p>Nuestra misión es proveer experiencias de viaje excepcionales que perduren en la memoria de nuestros clientes. Nos dedicamos a proveer servicios personalizados de alta calidad, asegurando la satisfacción en cada paso de su viaje. A través de nuestra amplia red de asociados y destinos nuestro objetivo es ofrecer un diversidad de oportunidades de viaje cautivadoras para diferentes intereses y preferencias. Nuestro compromiso es promover prácticas de turismo sustentable, respetando la cultura local, los recursos naturales y darles apoyo a las mismas. Nuestra misión es ser la opción confiable de viaje, inspirando y enpoderando a los individuos a explorar Guatemala de manera responsable mientras fomentamos una mayor apreciación y comprensión de nuestra diversidad cultural.</p>
            </div>
        </div>
        <div className="contact-whatsapp">
            <a href="https://wa.me/50258507070">
            <img src="../assets/wa.png" alt="WhatsApp" />
            </a>
        </div>
        <div>
            <FooterComponent />
        </div>
    </>
  )
}
