import React from 'react'
import { FooterComponent } from '../components/FooterComponent'
import { Link, useNavigate } from 'react-router-dom';
import '../styles/ThanksReservationPage.css';
import { IncludeComponent, PDFComponent, PickupPointComponent } from '../components';

export const ThanksReservationPage = ({ tourInformation, reservationCode, reservationData }) => {
  const navigate = useNavigate();
  const {
		title,
		// stars,
		// amountReviews,
		// photos,
		// price,
    // preciosPorPersona,
		// time,
		// description,
    schedules,
    // highlights,
		included,
		notIncluded,
    whatToExpect: departurePoint,
		// additionalInformation,
		// cancellationPolicy,
		// FAQ,
		// travelerPhotos,
		// reviews,
  } = tourInformation;
  console.log( reservationData );
  
  return (
    <>
        <div className='container'>
            <h1>¡Gracias por tu reserva!</h1>
            <hr />
            <p>Recibirás una confirmación con tu reserva a la brevedad posible.</p>
            <hr />
            <div className="grid-order-resume">
                <h2>{ title }</h2>
                <div className='grid-order-resume-text'>
                  <p><b>Horario:</b> { schedules }</p><p><b>Número de Reserva:</b> { reservationCode }</p>
                </div>
                <IncludeComponent included={included} notIncluded={notIncluded} />
                {/* <PDFComponent tourInformation={ reservationData } reservationCode={ reservationCode } /> */}
                <PickupPointComponent departurePoint={departurePoint} />
            </div>
            <hr /><br /><br />
            <div className='grid-order-resume-text'>
              <p><Link className='btn-link' to="../">Home</Link></p>
              <p><Link className='btn-link' to="/" onClick={ e => {
                e.preventDefault();
                navigate(-1);
              }}>{ title }</Link></p>
            </div>
        </div>
        <div>
            <FooterComponent />
        </div>
    </>
  )
}
