import React from 'react'
import { useCustomForm } from '../../hooks/useCustomForm';

export const LoginPage = () => {
  const [ values, handleInputChange, reset ] = useCustomForm({
    user: '',
    password: '',
  });

  const { user, password } = values;
  return (
    <div className='container'>
        <form>
            <div class="form-group">
                <label htmlFor="exampleInputEmail1">Usuario: </label>
                <input type="text" value={ user } className="form-control" onChange={ handleInputChange } id="exampleInputEmail1" aria-describedby="userHelp" />
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputPassword1">Contraseña: </label>
                <input type="password" value={ password } className="form-control" onChange={ handleInputChange } id="exampleInputPassword1" />
            <small id="emailHelp" className="form-text text-muted">Nunca compartas tu contraseña con nadie.</small>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
        </form>
    </div>
  )
}
