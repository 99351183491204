import { ContactComponent, FooterComponent } from '../components'

export const ShuttlesPage = () => {
  return (
    <>
        <div className='circuits'>
            {/* <h3>Próximamente...</h3>
            <div className="spinner-border" role='status'>
                <span className="sr-only">Loading...</span>
            </div> */}
            <img src="../assets/shuttle.jpg" alt="Shuttles" className='shuttles-img-main' />
        </div>
        <ContactComponent />
        <FooterComponent />
    </>
  )
}
