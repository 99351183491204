import { useState } from "react"
import { FooterComponent } from "../../components/FooterComponent"
import { AddRegisterComponent } from "./AddRegisterComponent"
import { RegisterTableComponent } from "./RegisterTableComponent"
import { ModalAddRegisterComponent } from "./ModalAddRegisterComponent"


export const DashboardPage = () => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  return (
    <>
        <div className="container">
            <h1 className="display-3 py-3 text-center">Agregar Registro</h1>
            <hr />
            <div className="container-fluid">
                <AddRegisterComponent setIsModalOpen={setIsModalOpen } />
                <hr />
                <RegisterTableComponent />
                <ModalAddRegisterComponent />
            </div>
        </div>
        <FooterComponent />
    </>
  )
}
