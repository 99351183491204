import { FooterComponent } from '../components/FooterComponent';
import { Link, useNavigate } from 'react-router-dom';

export const ThanksPage = () => {
  const navigate = useNavigate();
  return (
    <>
        <div className='container'>
            <h1>¡Gracias por escribirnos!</h1>
            <hr />
            <p>Nos contactaremos contigo lo más pronto posible</p>
            <hr />
            <p><Link className='btn-link' to="../">Home</Link> | <span className='btn-link' onClick={() => navigate(-1)}>Regresar a Tours</span></p>
        </div>
        <div>
            <FooterComponent />
        </div>
    </>
  )
}
