import React from 'react'
import { FooterComponent } from '../components/FooterComponent'
import { ThanksPage } from './ThanksPage'
import { useForm, ValidationError } from '@formspree/react'
import { ContactComponent } from '../components/ContactComponent'
import Swal from 'sweetalert2'

export const ContactPage = () => {
  const [ state, handleSubmit ] = useForm('mwkdyygk');

  const handleValidate = (e) => {
    // Declaration fields
    const cxName = document.getElementById('cxName');
    const cxEmail = document.getElementById('cxEmail');
    const cxPhone = document.getElementById('cxPhone');
    const cxMessage = document.getElementById('cxMessage');

    const input = e.target;

    if (input.name === 'name'){
      // Name validation
      if (input.value.length < 3){
        cxName.classList.add('is-invalid');
        cxName.focus();
      } else {
        cxName.classList.remove('is-invalid');
      }
    } else if (input.name === '_replyto' || input.name === 'phone_number'){
      // Email and phone validation
      if (cxEmail.value.trim().length === 0 && cxPhone.value.trim().length === 0 && input.name === 'phone_number'){
        cxEmail.classList.add('is-invalid');
        cxPhone.classList.add('is-invalid');
        Swal.fire({
          title: "Oops... algo salió mal",
          text: "Por favor llenar su teléfono o correo electrónico",
          icon: "error",
          confirmButtonColor: '#336699',
        }).then( result => {
          if (result.isConfirmed) {
            cxEmail.focus();
          }
        });
      } else if (input.name === '_replyto'){
        // Validating email
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.value) && cxPhone.value.trim().length === 0){
          cxEmail.classList.add('is-invalid');
        } else {
          cxEmail.classList.remove('is-invalid');
          cxPhone.classList.remove('is-invalid');
        }
      } else if (input.name === 'phone_number'){
        // Validating Phone
        if (!/^(\+\d{1,4})?[\s.-]?\(?\d{1,}\)?[\s.-]?\d{1,}[\s.-]?\d{1,}[\s.-]?\d{1,}$/.test(input.value) && cxEmail.value.trim().length === 0){
          cxPhone.classList.add('is-invalid');
          cxPhone.focus();
        } else{
          cxEmail.classList.remove('is-invalid');
          cxPhone.classList.remove('is-invalid');
        }
      }
    } else if (input.name ==='message'){
      // Messagevalidation
      if (input.value.trim().length <= 12){ // tour a Guate
        cxMessage.classList.add('is-invalid');
        cxMessage.focus();
      } else {
        cxMessage.classList.remove('is-invalid');
      }
    }
  };

  const validateForm = e => {
    e.preventDefault();
    // Declaration fields
    const cxName = document.getElementById('cxName');
    const cxEmail = document.getElementById('cxEmail');
    const cxPhone = document.getElementById('cxPhone');
    const cxMessage = document.getElementById('cxMessage');

    if (cxName.value.trim().length === 0 ||
       (cxEmail.value.trim().length === 0 && cxPhone.value.trim().length === 0) ||
        cxMessage.value.trim().length <= 12
    ){
      // Evaluating individual fields
      if (cxName.value.trim().length === 0 &&
      (cxEmail.value.trim().length === 0 && cxPhone.value.trim().length === 0) &&
      cxMessage.value.trim().length <= 12){
        // None of fields were given
        Swal.fire({
          title: "Reserva fallida :(",
          text: "Por favor ingrese toda la información para asistirle de la mejor manera posible",
          icon: "error",
          confirmButtonColor: '#336699',
        }).then( result => {
          if (result.isConfirmed) {
            cxName.focus();
          }
        });
      } else if (cxName.value.trim().length !== 0 &&
      (cxEmail.value.trim().length === 0 && cxPhone.value.trim().length === 0) &&
      cxMessage.value.trim().length <= 12){
        // Name field was given
        Swal.fire({
          title: "Reserva fallida :(",
          text: "Por favor ingrese su correo eléctronio o teléfono y su mensaje por favor.",
          icon: "error",
          confirmButtonColor: '#336699',
        }).then( result => {
          if (result.isConfirmed) {
            cxEmail.focus();
          }
        });
      } else if (cxName.value.trim().length !== 0 &&
      (cxEmail.value.trim().length !== 0 || cxPhone.value.trim().length !== 0) &&
      cxMessage.value.trim().length <= 12){
        // Name and contact fields were given
        Swal.fire({
          title: "Reserva fallida :(",
          text: "Por favor ingrese su mensaje.",
          icon: "error",
          confirmButtonColor: '#336699',
        }).then( result => {
          if (result.isConfirmed) {
            cxMessage.focus();
          }
        });
      } else if (cxName.value.trim().length === 0 &&
      (cxEmail.value.trim().length !== 0 || cxPhone.value.trim().length !== 0) &&
      cxMessage.value.trim().length > 12){
        // Contact and message fields were given
        Swal.fire({
          title: "Reserva fallida :(",
          text: "Por favor ingrese su nombre.",
          icon: "error",
          confirmButtonColor: '#336699',
        }).then( result => {
          if (result.isConfirmed) {
            cxName.focus();
          }
        });
      } else if (cxName.value.trim().length !== 0 &&
      (cxEmail.value.trim().length === 0 || cxPhone.value.trim().length === 0) &&
      cxMessage.value.trim().length > 12){
        // Contact and message fields were given
        Swal.fire({
          title: "Reserva fallida :(",
          text: "Por favor ingresa tu información de contacto para que podamos responderte.",
          icon: "error",
          confirmButtonColor: '#336699',
        }).then( result => {
          if (result.isConfirmed) {
            cxEmail.classList.add('is-invalid');
            cxPhone.classList.add('is-invalid');
            cxMessage.classList.remove('is-invalid');
            cxEmail.focus();
          }
        });
      }
      return false;
    } else{
      return true;
    }
  };

  if (state.succeeded) {
    return <ThanksPage />
  }
  return (
    <>
        <div className='container'>
            <h1>Contáctanos</h1>
            <hr />
            {/* Form */}
            <form onSubmit={ e => {
              if ( validateForm(e) ){
                  handleSubmit(e);
              } else{
                state.succeeded = false;
              }
            }}>
                <label htmlFor="name" >Nombre:</label><br/>
                <input className="form-control" id="cxName" type="text" name="name" onBlur={ handleValidate } autoComplete='off'/><div className="invalid-feedback">Ingrese su nombre</div><br/>
                <label htmlFor="email" >Correo Electrónico:</label><br/>
                <input className="form-control" id="cxEmail" type="email" name="_replyto" onBlur={ handleValidate } autoComplete='off'/> <div className="invalid-feedback">Ingrese un correo electrónico válido.</div>
                <label htmlFor="phone">Teléfono:</label><br />
                <input className="form-control" id="cxPhone" type="tel" name="phone_number" onBlur={ handleValidate } autoComplete='off' /><div className="invalid-feedback">Ingrese un número de teléfono válido.</div><br/> 
                <br /><br />
                <textarea className="form-control" rows={5} cols={30} placeholder="Mensaje..." id="cxMessage" name="message" onBlur={ handleValidate }></textarea><div className="invalid-feedback">Por favor déjanos saber como podemos ayudarte.</div><br /><br/>
                <ValidationError prefix='message' field='message' errors={state.errors} />
                <button className="btn form-control btn-outline-primary" type="submit">Enviar Mensaje</button>
            </form>
            <div className="contact-information">
                <hr />
                <p>Teléfono: <b>+502 5850 7070</b></p>
                <p>Email:    <b>info@turismoguate.com</b></p>
            </div>
        </div>
        <ContactComponent />
        <div>
            <FooterComponent />
        </div>
    </>
  )
}
