import React from 'react'

export const IncludeComponent = ({ included, notIncluded }) => {
  return (
    <div className="tour-included">
        <div className="included">
            <h3>Incluye</h3>
            {
            included.map( (element, i) => {
                return (
                <div className='tour-item-included' key={ `included-${i}` }>
                    <img src="../assets/check.png" alt="check" className="included-dots" />
                    <span className='text-included'> {element} </span>
                </div>
                );
            })
            }
        </div>
        <div className="not-included">
            <h3>No Incluye</h3>
            {
                notIncluded.map( (element, i) => {
                return (
                    <div className='tour-item-included' key={ `not-included-${i}` }>
                    <img src="../assets/close.png" alt="close" className="included-dots" />
                    <span className='text-included'> {element} </span>
                    </div>
                );
                })
            }
        </div>
    </div>
  )
}
