import { FooterComponent } from '../components/FooterComponent'
import { circuits } from '../helpers/circuits'
import '../styles/Circuit.css'

export const CircuitsPage = () => {
  const { guatemalaExpress } = circuits;
  const { title, shortDescripction } = guatemalaExpress;
  return (
    <>
        <div className='circuit'>
          <div className="circuit-container">
            <div className="circuit-top-title">
              <hr />
              <h1>{title}</h1>
              <hr />
            </div>
            <div className="circuit-short-description">
              <p>{shortDescripction}</p>
            </div>
          </div>
        </div>
        <div className='circuits'>
            {/* <h3>Próximamente...</h3>
            <div className="spinner-border" role='status'>
                <span className="sr-only">Loading...</span>
            </div> */}
            <img src="../assets/circuits.jpg" alt="Circuits-turismo-guate" className='shuttles-img-main' />
        </div>
        <FooterComponent />
    </>
  )
}
