

export const RegisterTableComponent = () => {
  const example = [{
    n: 1,
    fecha: new Date(),
    cliente: 'Josue Martinez',
    pax: 2,
    servicio: 'Antigua',
    hotel: 'H INN',
    contacto: 'Sally',
    status: 'reservada',
    rack: 200,
    comic: 20,
    saldo: 200 - 20,
    neta: 60,
    free: 180 - 60,
  }];
  return (
    <div className="col-md-8 offset-md-2 py-5">
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Fecha</th>
                    <th>Cliente</th>
                    <th>Pax</th>
                    <th>Servicio</th>
                    <th>Hotel</th>
                    <th>Contacto</th>
                    <th>Status</th>
                    <th>Rack</th>
                    <th>Comic</th>
                    <th>Saldo</th>
                    <th>Neta</th>
                    <th>Free</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
              {
                example.map( ({ n, fecha, cliente, pax, servicio, hotel, contacto, status, rack, comic, saldo, neta, free }, index) => {
                  return (
                    <tr key={n}>
                      <td>{ index + 1 }</td>
                      <td>{ `${fecha.getDay()}/${fecha.getMonth()}/${fecha.getFullYear()}` }</td>
                      <td>{ cliente }</td>
                      <td>{ pax }</td>
                      <td>{ servicio }</td>
                      <td>{ hotel }</td>
                      <td>{ contacto }</td>
                      <td>{ status }</td>
                      <td>{ rack }</td>
                      <td>{ comic }</td>
                      <td>{ saldo }</td>
                      <td>{ neta }</td>
                      <td>{ free }</td>
                    </tr>
                  )
                })
              }
            </tbody>
        </table>
    </div>
  )
}
