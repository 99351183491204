import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavbarComponent } from './NavbarComponent';
import { MenuComponent } from './MenuComponent';

export const HeaderComponent = () => {
  const [active, setActive] = useState(['nav-link', 'nav-link', 'nav-link', 'nav-link', 'nav-link', 'nav-link',]);
  const navigate = useNavigate();

  const handleClick = e => {
	e.preventDefault();
	const section = e.target.text;
	if (section === 'Home'){
		setActive(['nav-link active', 'nav-link', 'nav-link', 'nav-link', 'nav-link', 'nav-link',]);
		navigate('../');
	} else if (section === 'Acerca de Nosotros'){
		setActive(['nav-link', 'nav-link active', 'nav-link', 'nav-link', 'nav-link', 'nav-link',]);
		navigate('../about');
	} else if (section === 'Tours desde Guatemala City'){
		setActive(['nav-link', 'nav-link', 'nav-link active', 'nav-link', 'nav-link', 'nav-link',]);
		navigate('../tours-from-guatemala-city');
	} else if (section === 'Tours desde Antigua Guatemala'){
		setActive(['nav-link', 'nav-link', 'nav-link', 'nav-link active', 'nav-link', 'nav-link',]);
		navigate('../tours-from-antigua-guatemala');
	} else if (section === 'Tours desde Tikal/Flores'){
		setActive(['nav-link', 'nav-link', 'nav-link', 'nav-link', 'nav-link active', 'nav-link',]);
		navigate('../tours-from-flores');
	} else if (section === 'Contáctanos'){
		setActive(['nav-link', 'nav-link', 'nav-link', 'nav-link', 'nav-link', 'nav-link active',]);
		navigate('../contact');
	}
  };

  return (
    <header onClick={handleClick} className={active && 'header'}>
		{/* <NavbarComponent /> */}
		<MenuComponent />
	</header>
  )
}
