

export const PickupPointComponent = ({ departurePoint }) => {
  return (
    <div className="tour-pickup">
        <h3>Punto de Encuentro</h3>
        {/* <span>El servicio de transporte de ida y vuelta parte y regresa a su hotel</span> */}
        {/* <span>El transporte sale de la zona 10 de Ciudad de Guatemala, si se encuentra hospedado en esta zona se le puede recoger en su hotel</span> */}
        <span>{ departurePoint.place } | { departurePoint.direction }</span>
        <iframe title='departurePoint' className='map-site' src={ departurePoint.urlLocation } allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}
