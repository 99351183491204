
export const CalificationSection = ({ stars, amountReviews }) => {

  const displayStars = ( starsQuantity ) => {
    switch (starsQuantity) {
      case 0.5:
        return (
          <div>
            <img src="../assets/clasificacion.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
          </div>
        );
      case 1:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
          </div>
        );
      case 1.5:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/clasificacion.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
          </div>
        );
      case 2:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
          </div>
        );
      case 2.5:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/clasificacion.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
          </div>
        );
      case 3:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
          </div>
        );
      case 3.5:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/clasificacion.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
          </div>
        );
      case 4:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/estrella.png" alt="star" className="calification-star" />
          </div>
        );
      case 4.5:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/clasificacion.png" alt="star" className="calification-star" />
          </div>
        );
      case 5:
        return (
          <div>
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
            <img src="../assets/star.png" alt="star" className="calification-star" />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="calification">
        {
          displayStars( stars )
        }
        <span> { amountReviews }</span>
    </div>
  )
}
