import { Navigate, Route, Routes } from "react-router-dom"
import { HomePage, TourPage, AntiguaToursPage, GuateToursPage, PanaToursPage, FloresToursPage, PDFComponent } from "../components/index";
import { AboutPage, CircuitsPage, ContactPage, PrivacyPage, PrivateTransportationPage, ShuttlesPage, TermsPage, ThanksReservationPage } from "../Pages";
import { LoginPage, DashboardPage } from "../private";


export const AppRouter = () => {
  return (
    <Routes>
        <Route exact path="/" element={ <HomePage />} />
        <Route exact path="/about" element={ <AboutPage />} />
        <Route exact path="/contact" element={ <ContactPage />} />
        <Route exact path="/tours-from-guatemala-city" element={ <GuateToursPage />} />
        <Route exact path="/tours-from-antigua-guatemala" element={ <AntiguaToursPage />} />
        <Route exact path="/tours-from-panajachel" element={ <PanaToursPage />} />
        <Route exact path="/tours-from-flores" element={ <FloresToursPage />} />
        <Route exact path="/tours/:tourId" element={ <TourPage /> } />
        <Route exact path='/circuits' element={ <CircuitsPage /> } />
        <Route exact path='/shuttles' element={ <ShuttlesPage /> } />
        <Route exact path='/private-transportation' element={ <PrivateTransportationPage /> } />
        <Route exact path='/support/terms-and-conditions' element={ <TermsPage /> } />
        <Route exact path='/support/privacy-policy' element={ <PrivacyPage /> } />
        <Route exact path='/private/login' element={<LoginPage />} />
        <Route exact path='/private/dashboard' element={<DashboardPage />} />
        <Route exact path='/test/thanks' element={<ThanksReservationPage tourInformation={{title: 'Test tour', schedules: '7:00 - 19:00', included: ['item 1', 'item 2', 'item 3'], notIncluded: ['item 4', 'item 5'], whatToExpect: {place: 'Aeropuerto Internacional "La Aurora"', direction: '9 Avenida 14-75, zona 13, Ciudad de Guatemala, 00013', urlLocation: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.194756422414!2d-90.53326562466715!3d14.58797557738519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a15f18a23e65%3A0xa8d9f9b1ae2d4eca!2sAeropuerto%20Internacional%20La%20Aurora!5e0!3m2!1ses!2sgt!4v1689269764953!5m2!1ses!2sgt',}}} reservationCode={`G20#232250`} />} />
        <Route exact path='/test/pdf' element={<PDFComponent />} />
        <Route path="/*" element={ <Navigate to="/" replace={true} />} />
    </Routes>
  )
}
