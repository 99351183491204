import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    divisorLine: {
        border: 1,
        borderColor: 'black',
        marginHorizontal: 30,
        width: '90%',
    },
    fields: {
        flexDirection: "row",
        flexGrow: 1,
        // borderColor: 'blue',
        // borderWidth: 1,
        width: '75%',
    },
    fieldsSubject: {
        flexDirection: "row",
        flexGrow: 1,
        fontWeight: 'bold',
        // borderColor: 'blue',
        // borderWidth: 1,
        width: '25%',
    },
    footer: {
        alignItems: 'center',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        textAlign: 'center',
    },
    footerText: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 65,
        paddingHorizontal: 30,
        position: 'relative',
    },
    greetSection: {
        // flexGrow: 1, 
        marginTop: 0,
        paddingHorizontal: 30,
        paddingVertical: 10,
    },
    icono: {
        alignSelf: 'center',
        width: "25%",
    },
    infoSection: {
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: 30,
        // borderColor: 'green',
        // borderWidth: 1,
    },
    infoSectionColumn: {
        // border: 1,
        // borderColor: 'orange',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        height: 50,
        justifyContent: 'center',
        marginBottom: 60,
    },
    infoSectionDetail: {
        // border: 1,
        // borderColor: 'orange',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
    },
    itemsColumn: {
        // border: 1,
        // borderColor: 'blue',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        marginTop: -10,
        width: '75%',
    },
    itemColumn: {
        // border: 1,
        // borderColor: 'green',
        height: '5px',
        marginVertical: 10,
    },
    link: {
        color: '#336699', // azul
        textDecoration: 'none',
    },
    page: {
        backgroundColor: '#E4E4E4', // Gris
        flexDirection: 'column',
    },
    paymentWarning: {
        color: 'red',
        fontSize: 9,
        paddingHorizontal: 30,
    },
    subject: {
        fontWeight: 'bold',
    },
    title: {
        color: "#2678C5", // Azul
        fontSize: 34,
        fontWeight: 'bold',
        marginTop: 10,
        textAlign: "center",
    },
    titleSection: {
        marginTop: 10,
        marginBottom: 0,
        paddingLeft: 10,
        paddingRight: 10,
    },
    totalPrice: {
        paddingHorizontal: 30,
        textAlign: 'right',
    },
  });