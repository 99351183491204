import React from 'react'
import { CalificationSection } from './CalificationSection'
import { useNavigate } from 'react-router-dom';

export const TourOverview = ({ cardImg, cardImgAlt, cardTitle, cardLink, cardPrice, cardCalification, cardAmountComments }) => {
  const navigate = useNavigate();

  const handleClick = (e, link) => {
    e.preventDefault();
    navigate( link );
  };
  return (
    <>
        <div className="tour-container-overview">
            <a href={ cardLink } className="tour-container-overview-link" onClick={ (event) => handleClick(event, cardLink) }>
                <img src={ `../assets/${cardImg}` } alt={ cardImgAlt } className="tour-prev-img" />
                <div className="tour-overview-info">
                    <h1>{ cardTitle }</h1>
                    <div className="row">
                        <div className="col-7">
                            <CalificationSection stars={cardCalification} amountReviews={cardAmountComments} />
                        </div>
                        {/* <div className='price-col'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="113" height="113">
                                <circle cx="55" cy="55" r="54" fill="red"/>
                                <text x="58" y="50" text-anchor="middle" font-size="20" fill="yellow" font-weight="light">Desde...</text>
                                <text x="58" y="70" text-anchor="middle" font-size="20" fill="yellow" font-weight="bold">$ { cardPrice.toFixed(2) }</text>
                            </svg>
                        </div> */}
                        <div className="price-col">
                            <img src={cardPrice} className='icono-precio' alt="Precio" />
                            {/* <svg width={200} height={100} smlns="http://www.w3.org/2000/svg">
                                <rect x={10} y={10} width={180} height={80} rx={10} ry={10} fill='yellow' stack='black'strokeWidth={2} />
                                <text x="50%" y="50%" dominantBaseline={"middle"} textAnchor='middle' fontFamily='Arial' fontSize={20} fill='black'>250</text>
                            </svg> */}
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </>
  )
}
