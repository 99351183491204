import { Link } from "react-router-dom"
import { ContactComponent } from "../components/ContactComponent"
import { FooterComponent } from "../components/FooterComponent"


export const TermsPage = () => {
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const today = new Date();
  const year = today.getFullYear();
  const month = months[today.getMonth()];
  return (
    <>
      <h1>Términos y Condiciones de TurismoGuate.com</h1>
      <hr />
      <div className="container" style={{ textAlign: 'justify', }}>
        <p>Bienvenido a Turismo Guate, su compañero confiable para servicios turísticos en Guatemala. Antes de utilizar nuestros servicios, le recomendamos leer detenidamente los siguientes términos y condiciones.</p><br />

        <h3>1. Información Personal Recopilada:</h3>
        <p>Al utilizar nuestro sitio web y servicios, usted acepta proporcionar la siguiente información personal: nombre, dirección de correo electrónico, número de teléfono, fechas de viaje y número de acompañantes. Esta información es necesaria para garantizar una experiencia turística personalizada y eficiente. La información será utilizada únicamente con propósitos de tomar reservas.</p><br />

        <h3>2. Uso de la Información:</h3>
        <p>La información personal recopilada se utilizará exclusivamente para procesar sus solicitudes de servicios turísticos, brindarle información relevante sobre su viaje y mejorar nuestros servicios. Nos comprometemos a no compartir su información con terceros sin su consentimiento expreso, excepto cuando sea necesario para cumplir con requisitos legales.</p><br />

        <h3>3. Seguridad de la Información:</h3>
        <p>Tomamos medidas razonables para garantizar la seguridad de su información personal. Sin embargo, tenga en cuenta que ninguna transmisión por Internet o almacenamiento electrónico es completamente seguro. Al proporcionar su información, acepta los riesgos asociados.</p><br />

        <h3>4. Cookies y Tecnologías Similares:</h3>
        <p>Utilizamos cookies y tecnologías similares para mejorar su experiencia en nuestro sitio web. Puede ajustar la configuración de su navegador para gestionar estas tecnologías, pero tenga en cuenta que esto puede afectar la funcionalidad de ciertas partes de nuestro sitio.</p><br />

        <h3>5. Cancelaciones y Reembolsos:</h3>
        <p>Consulte nuestras políticas de cancelación y reembolso para obtener información detallada sobre los procedimientos y condiciones aplicables.</p><br />
        <p>A continuación le mostramos una lista en los casos que no aplica un reembolso:</p>
        <ul>
          <li><b>Anticipos para granatizar servicios.</b> Esto porque hemos inveritdo tiempo y dinero para garantizar el servicio o servicios reservaods.</li>
          <li><b>Cancelaciones con menos de 24 horas de anticipación.</b> El serivico no podrá ser cancelado ni reembolsado </li>
          <li><b>No Show.</b> No nos hacemos responsables por no poder tomar servicios por incumplimiento de horarios, retrasos o adelantos de vuelos, retrasos personales, incapacidad de tomar el tour ya reservado y pagado, ni alguna otra circunstancia que impida presentarse al servicio requerido. Si se produce un <i>No Show</i>, este no es reembolsable.</li>
          <li><b>Cancelaciones.</b> En caso de una cancelación se verificará el caso. Pueden existir intercambio de fechas sujeto a disponibilidad.</li>
          <li><b>Cotizaciones No Confirmadas.</b> Para todo servicio que que no haya sido confirmado mediante el pago de un anticipo o el servicio complete dependiendo de los servicios.</li>
        </ul>

        <h3>6. Derechos del Usuario:</h3>
        <p>Usted tiene derecho a acceder, corregir o eliminar su información personal. Para ejercer estos derechos o realizar preguntas sobre nuestra <Link className="stretched-link" to={`../support/privacy-policy`}>política de privacidad</Link>, comuníquese con nuestro equipo de atención al cliente.</p><br />

        <h3>7. Cambios en los Términos y Condiciones:</h3>
        <p>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Se le notificará sobre cambios significativos. Al continuar utilizando nuestros servicios después de dichas modificaciones, acepta los términos revisados.</p><br />

        <h3>8. Ley Aplicable:</h3>
        <p>Estos términos y condiciones se rigen por las leyes de Guatemala. Cualquier disputa se resolverá mediante negociación de buena fe o, en su defecto, mediante los tribunales competentes de Guatemala.</p><br />

        <h3>9. Uso General</h3>
        <p>Turismo Guate no se hace responsable por ojetos olvidados o perdios durante los servicios y dentro de nuestros vechículos. Usted viaja por su propia cuenta y riesgo. Turismo Guate no se hace responsable por heridas o accidentes durante nuestros servicios.</p>

        <h3>10. Tours Personalizados y Cotizaciones</h3> 
        <p>Aunque tenemos una cantidad limitada de servicios disponibles en nuestro sitio web, puede ponerser en contacto con nosotros <Link className="stretched-link" to="../contact">aquí</Link> para solicitar servicios personalizados que se adapten a sus necesitas únicas, tiempos deseados y giras que se ajustan a cualquier  itinerario. Estas cotizaciones especiales tienen diferentes previos, esto se debe a que invertimos tiempo y esfuerzo para realizar itinerarios que se ajusten a cada necesidad. Si algo no se ajusta a su persona y desea modificar un tour a su gusto este podría cambiar de precio ya que es posible que se requiera un tour privado para operar su solicitud.</p>

        <h3>11. Precios</h3>
        <p>Todos los precios en nuestro sitio web están sujetos a cambios sin previo aviso en cualquier momento. Los precios varian con el tiempo dependiendo de múltiples factores incluyendo pero no limitados a la cantidad de personas que compren juntas y la fecha en la que se viaje, se toman en cuenta también las festividades y temporadas especiales para organizar un tour. Otras agencias de viajes no reciben descuentos en nuestros tours. Agradecemos su compresión en caso que se presente un cambio de precio no anticipado.</p>

        <h3>12. Tours</h3>      
        <p>Todos nuestros tours son compartidos y están sujetos a cambio de horarios según disponibilidd. Debido a que nuestros tours son compartidos debe estar 15 minutos antes listo en el punto de partida para evitarque el transporte continue sin su persona.</p>

        <p>Al utilizar nuestros servicios, usted acepta cumplir con estos términos y condiciones. Gracias por elegir Turismo Guate para su experiencia turística en Guatemala. ¡Esperamos que tenga un viaje increíble!</p><br />

        <p><small>Fecha de última actualización: { month } de { year }</small></p>
        <div>
          <p style={{ textAlign: 'right'}}>
            <Link className="stretched-link" to={ `../`}>Home</Link>
          </p>
        </div>
      </div>
      <ContactComponent />
      <FooterComponent />
    </>
  )
}
