// import { CarouselComponent } from "./CarouselComponent"
import { Link, useNavigate } from "react-router-dom"
import { FooterComponent } from "./FooterComponent"
import { ContactComponent } from "./ContactComponent";

export const HomePage = () => {
  const navigate = useNavigate();

  const handleClick = (e, link) => {
    e.preventDefault();
    
    navigate( link );
  };
  return (
    <>
      {/* DESKTOP VERSION */}
      <div className="standard-version">
        <img src="../assets/visit-guate.jpeg" className="img-fluid" alt="Guatemala" id="background-img" />
        <div className="container-from">
          <div className="connection-text">
            <h5>Tours saliendo desde</h5>
          </div>
        </div>
        <div className="row tours-from">
          <div className="col-4">
            <a href="./tours-from-guatemala-city" onClick={ (event) => handleClick(event, './tours-from-guatemala-city') }> {/*  */}
              <img src="../assets/guatemala-city.jpg" alt="Guatemala City" className="tours-from-img" />
              <p href="./tours-from-guatemala-city">Guatemala City</p>
            </a>
          </div>
          <div className="col-4">
            <a href="./tours-from-antigua-guatemala" onClick={ (event) => handleClick(event, './tours-from-antigua-guatemala') }> {/*  */}
              <img src="../assets/antigua.jpeg" alt="Antigua Guatemala" className="tours-from-img" />
              <p>Antigua Guatemala</p>
            </a>
          </div>
          {/* <div className="col-3">
            <img src="../assets/atitlan-lago.jpg" alt="" className="tours-from-img" />
            <p>Panajachel</p>
          </div> */}
          <div className="col-4">
            <a href="./tours-from-flores" onClick={ (event) => handleClick(event, './tours-from-flores') }> {/*  */}
              <img src="../assets/tikal-ruins.jpg" alt="Tikal" className="tours-from-img" />
              <p>Tikal/Flores</p>
            </a>
          </div>
        </div>
        <ContactComponent />
      </div>
      {/* CHANGE TO PHONE VERSION */}
      <div className="phone-version">
        <div className="row">
          <div className="landingPage">
            <img src="../assets/Guate.jpg" className="img-fluid" alt="Lago de Atitlan" id="background-img" />
            {/* <h3>Welcome to... </h3><h1>Guatemala</h1> */}
          </div>
        </div>
        {/* <div className="carousel">
          <CarouselComponent />
        </div> */}
        <div className="container">
          <div className="connection-text">
            Tours saliendo desde
          </div>
        </div>
        <div className="row tours-from">
          <div className="col-12">
            <img src="../assets/guatemala-city.jpg" alt="Guatemala City" className="tours-from-img" />
            <p><a className="tour-from-link" href="./tours-from-guatemala-city">Guatemala City</a></p>
          </div>
          <div className="col-12">
            <img src="../assets/antigua.jpeg" alt="" className="tours-from-img" />
            <p><a className="tour-from-link" href="./tours-from-antigua-guatemala">Antigua Guatemala</a></p>
          </div>
          {/* <div className="col-12">
            <img src="../assets/atitlan-lago.jpg" alt="" className="tours-from-img" />
            <p><a className="tour-from-link" href="./tours-from-panajachel">Panajachel</a></p>
          </div> */}
          <div className="col-12">
            <img src="../assets/tikal-ruins.jpg" alt="" className="tours-from-img" />
            <p><a className="tour-from-link" href="./tours-from-flores">Tikal/Flores</a></p>
          </div>
        </div>
        <div className="additional-access">
          <Link to="../contact"><button className="btn btn-outline-info">Contactanos</button></Link>
          <Link to="../about"><button className="btn btn-outline-info">Sobre Nosotros</button></Link>
        </div>
        <ContactComponent />
      </div>
      <FooterComponent />
    </>
  )
}
