import { Link } from "react-router-dom";
import { ContactComponent } from "../components/ContactComponent"
import { FooterComponent } from "../components/FooterComponent"


export const PrivacyPage = () => {
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const today = new Date();
  const year = today.getFullYear();
  const month = months[today.getMonth()];
  return (
    <>
      <h1>Política de Privacidad de TurismoGuate.com</h1>
      <hr />
      <div className="container" style={{ textAlign: 'justify', }}>
        <h3>Información Recopilada</h3>
        <p>
            Para procesar sus reservaciones y brindarle servicios personalizados, recopilamos la siguiente información:
            <br />
            <ul>
                <li>Nombre y apellidos</li>
                <li>Dirección de correo electrónico</li>
                <li>Número de teléfono</li>
                <li>Información de pago (cuando sea aplicable)</li>
                <li>Fechas de viaje</li>
                <li>Preferencias y requisitos específicos para su viaje</li>
            </ul>
        </p>

        <h3>2. Uso de la Información:</h3>
        <p>
            La información recoplidad se utilizará para:
            <br />
            <ul>
                <li>Procesar y confirmar sus reservaciones.</li>
                <li>Comunicarnos con usted sobre detalles relevantes de su viaje.</li>
                <li>Personalizar nuestros servicios según sus preferencias.</li>
                <li>Mejorar y optimizar nuestro sitio web y servicios.</li>
            </ul>
        </p>

        <h3>3. Seguridad de la Información:</h3>
        <p>Tomamos medidas razonables para garantizar la seguridad de su información. Utilizamos protocolos de seguridad estándar y colaboramos con proveedores de confianza para proteger sus datos contra accesos no autorizados o alteraciones.</p>

        <h3>4. Compartir Información:</h3>
        <p>No compartiremos su información personal con terceros, excepto cuando sea necesario para cumplir con sus reservaciones (por ejemplo, con proveedores de servicios turísticos) o cuando la ley lo exija.</p>

        <h3>5. Contacto </h3>
        <p>Para preguntas, solicitudes o preocupaciones relacionadas con su privacidad, contáctenos a través de <a href="mailto:info@turismoguate.com?Subject=Politica%20de%20Privacidad" className="stretched-link">info@turismoguate.com</a>.</p>

        <p><small>Fecha de última actualización: { month } de { year }</small></p>
        <div>
          <p style={{ textAlign: 'right'}}>
            <Link className="stretched-link" to={ `../`}>Home</Link>
          </p>
        </div>
      </div>
      <ContactComponent />
      <FooterComponent />
    </>
  )
}
