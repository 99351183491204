import React from 'react'

export const AddRegisterComponent = ({ setIsModalOpen }) => {

  const handleAddRegister = e => {
    e.preventDefault();
    setIsModalOpen( true );
  };

  return (
    <div className="row mt-3"> 
        <div className="col-md-4 offset-md-4">
            <div className="d-grid mx-auto">
                <button className="btn btn-dark" onClick={ handleAddRegister }  data-bs-toggle='modal' data-bs-target='#modalRegisters'>
                    <i className='fa-solid fa-circle-plus'></i> Agregar Registro
                </button>
            </div>
        </div>
    </div>
  )
}
