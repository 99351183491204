// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import React from 'react'

export const FooterComponent = () => {
  const f = new Date();
  return (
    <footer>
        {/* DESKTOP VERSION */}
        <hr/>
        <div className="footer-row">
            <div className="footer-media">
                {/* <a href="https://www.facebook.com/toursxguatemala" className="socialLink" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={icon({name: 'twitter', style: 'brands'})} /></a> */}
                <a href="https://www.facebook.com/turismoguate502" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-facebook"></i></span></a>
                <a href="https://www.instagram.com/toursxguate" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-instagram"></i></span></a>
                <a href="https://www.twitter.com/turismo_guate_" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-twitter"></i></span></a>
                <a href="https://www.youtube.com/@ToursGuate" className="socialLink" target="_blank" rel="noreferrer"><span><i className="fa-brands fa-youtube"></i></span></a>
                <a href='../support/terms-and-conditions' className='socialLink' rel='noreferrer'><i className="fa-solid fa-circle-exclamation"></i></a>
            </div><br />
            <div className="footer-brand">
                &copy; { f.getFullYear()} - Turismo Guate
            </div>
        </div>
    </footer>
  )
}
