import { TravelCenterGuatemalaApp } from './TravelCenterGuatemalaApp';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
// import 'sweetalert2-react-content';
import React from 'react';
import './App.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <TravelCenterGuatemalaApp />
  // </React.StrictMode>
);

