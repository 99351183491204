import '../styles/PriceTable.css'

export const PriceTableComponent = ({ preciosPorPersona }) => {
  return (
    <div className="tabla-precios">
        <h3 style={{color: 'red'}}>Precio por persona</h3>
        <table>
            <thead>
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>${preciosPorPersona[0]}</td>
                    <td>${preciosPorPersona[1]}</td>
                    <td>${preciosPorPersona[2]}</td>
                    <td>${preciosPorPersona[3]}</td>
                    <td style={{color: 'red'}}>${preciosPorPersona[4]}*</td>
                </tr>
            </tbody>
        </table>
        <p className='importantNotes' >* Tarifa aplica si compran juntos</p>
        {/* <p className='importantNotes' > Aceptamos pagos con tarjeta de crédito, más 10% de recargo</p> */}
    </div>
  )
}
