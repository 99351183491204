import React from 'react'
import { BrowserRouter } from "react-router-dom"
import { AppRouter } from './router/AppRouter'
import { HeaderComponent } from './components/HeaderComponent'

export const TravelCenterGuatemalaApp = () => {
  return (
    <>
      <BrowserRouter>
        <HeaderComponent />
        <AppRouter />
      </BrowserRouter>
    </>
  )
}
